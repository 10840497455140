<template>
  <div class="intro">
    <div>
      <div class="step-title">&nbsp;安装&nbsp;macOS&nbsp;版</div>
      <div class="step-box">
        <div>
          没有非中国大陆的 Apple ID ？
          <br>
          <br>
          <div class="step-info">
            <ul>
              <li>
                <span class="small-step-number">方法 1</span>
                <a :href="`/blog/macos-ru-he-zhu-ce-ao-da-li-ya-apple-id/?from=${from}`">
                   注册澳大利亚 Apple ID
                </a>
              </li>
              <li >
                <span class="small-step-number" style="opacity: 0">方法 1</span>
                <span>
                  (只属于您，强烈推荐)
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span class="small-step-number">方法 2</span>
                <a :href="`/blog/macos-shang/?from=${from}`">
                   使用公用 Apple ID
                </a>
              </li>
            </ul>
          </div>

        </div>
        <hr>
        <div>
          有非中国大陆的 Apple ID，直接安装 <br> <br>
          <div @click="downloadInstall(['download-button-macos-appstore'], $event)"
               class="download-button download-button-ios-appstore app-store-like">
            <span>
              <img class="app-icon" src="@/assets/images/icon_app.png"/>
            </span>
            <span>
              <ul>
                <li class="app-title">狗急加速</li>
                <li class="app-intro">将跳转至AppStore</li>
                <li class="app-get">安装</li>
              </ul>
            </span>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { onMounted, ref } from 'vue'
  import utils from "../utils/utils"

  export default {
    name: 'MacOS',
    components: {},
    props: {
      isFixed: {
        type: Boolean,
        default: false
      }
    },
    setup () {
      let stores = useStore()
      let store = stores.state

      let from = ref(utils.getQuery('from') || 'index')
      if (window.env && window.env.channel) from.value = window.env.channel
      if (window.location.href.includes('index')) from.value = 'index'

      function downloadInstall (className, event) {
        utils.statsDownload("macos")
        stores.commit('changeToPath', {tag: 'toIosAppStoreFaq', value: true})
        let resp = window.install('', className)
        if (!resp) event.preventDefault()
        return resp
      }


      onMounted(() => {
      })

      return {
        store,
        downloadInstall,
        from
      }
    },
  }
</script>

<style scoped>

  .intro .app-store-like .app-get, .intro .step-button, .intro a.step-button {
    background-color: #06f;
    border-radius: 20rem;
    border: none;
    color: #fff;
    padding: 0.2rem 1.2rem;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
  }

  .mdm-redeem-code {
    font-size: 1.6rem;
  }

  #mdm-redeem-code-cp {
    color: #0066FF;
  }

  .paid-icon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.5rem;
    display: block;
  }

  .intro .app-store-like .app-get.unpaid {
    background-color: #b2b2b2;
  }
  .intro .step-box .step-info {
    display: flex;
    justify-content: center;
  }
  .intro .step-box .step-info ul {
    text-align: left;
  }
  .intro .small-step-number{
    background-color: black;
    color: white;
    border-radius: 10rem;
    border: none;
    width: auto;
    height: auto;
    line-height: 1.6rem;
    font-size: 1.2rem;
    text-align: center;
    vertical-align: top;
    padding: 1px 8px;
  }
  .intro .step-box .step-info a{
    margin-left: 4px;
  }
</style>
